<template>
  <b-card>
    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <b-form-group label="Name (*):" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                        @input="generateSlug(model.name)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Slug:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Slug"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.slug"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Slug"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Parent category:" label-cols-md="3">
                    <treeselect
                      v-model="model.parent_id"
                      :multiple="false"
                      :clearable="true"
                      :searchable="true"
                      :open-on-click="true"
                      :clear-on-select="true"
                      :options="items"
                      :limit="5"
                      :max-height="300"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="12">
                  <b-form-group label="Parent category:" label-cols-md="3">
                    <v-select
                      v-model="model.parent_id"
                      label="name"
                      :reduce="x => x.id"
                      :options="items"
                    />
                  </b-form-group>
                </b-col> -->
                <b-col md="12">
                  <b-form-group label="Description:" label-cols-md="3">
                    <b-form-textarea
                      v-model="model.content"
                      placeholder="Description"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <b-form-group label="Display:" label-cols-md="3">
                    <b-form-checkbox
                      v-model="model.is_active"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Photo:" label-cols-md="3">
                    <div
                      class="d-flex align-items-center justify-content-center"
                      style="max-width: 200px;"
                    >
                      <b-aspect aspect="1:1">
                        <b-img-lazy
                          :src="model.avatar"
                          fluid
                          thumbnail
                          rounded
                          style="position: absolute; max-width: 200px;"
                        />
                        <div
                          class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
                        >
                          <b-form-file
                            accept=".jpg, .png, .gif"
                            class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                            @change="previewFiles"
                          />
                          <b-button
                            variant="outline-primary"
                            class="btn-icon rounded-circle btn-add"
                          >
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                        </div>
                      </b-aspect>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- Submit -->
            <b-col md="12" class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BAspect,
  BImgLazy,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardBody,
    BForm,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BAspect,
    BImgLazy,
    BFormFile,
    BFormCheckbox,
    Treeselect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: null,
        name: null,
        is_active: true,
        slug: null,
        parent_id: null,
        content: null,
      },
      activeOptions: Config.activeOptions,
      items: [],
    }
  },
  mounted() {
    this.loadList()
    // eslint-disable-next-line prefer-destructuring
    // eslint-disable-next-line operator-linebreak
    this.category_name =
      this.$route.params && this.$route.params.id
        ? this.$route.params.id.split('-')[1]
        : ''
  },
  methods: {
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    handleSort(data) {
      this.items = data
    },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_category/tree?template_id=${templateId}&limit=1000`,
        )
        if (result.status === 200) {
          const res = result.data.data
          // this.perPage = res.count_page
          // this.currentPage = res.current_page
          // this.totalRows = res.items.length
          // eslint-disable-next-line array-callback-return
          res.map(x => {
            const item = {
              id: x.id,
              label: x.name,
              children: [],
            }
            if (x.children && x.children.length > 0) {
              // eslint-disable-next-line no-param-reassign
              // eslint-disable-next-line array-callback-return
              x.children.map(y => {
                const i = {
                  id: y.id,
                  label: y.name,
                }
                item.children.push(i)
              })
            }
            this.items.push(item)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        name: this.model.name,
        is_active: Number(this.model.is_active === true ? 1 : 0),
        slug: this.model.slug,
        content: this.model.content,
      }
      if (this.model.avatar) {
        params.avatar = this.model.avatar
      }
      if (this.model.parent_id !== null) {
        params.parent_id = this.model.parent_id
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_category`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Product Category success',
                  },
                })
                this.$router.push('/product_category/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
