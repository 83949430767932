<template>
  <b-row>
    <b-col md="3">
      <ProductCategorySort :list="items" />
    </b-col>
    <b-col md="9">
      <b-tabs>
        <b-tab title="General">
          <ProductCategoryAdd />
        </b-tab>
        <b-tab title="Category Product">
          <ProductList />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductCategorySort from './ProductCategorySort.vue'
import ProductList from './ProductList.vue'
import ProductCategoryAdd from './ProductCategoryAdd.vue'

export default {
  components: {
    BRow,
    BCol,
    ProductCategorySort,
    BTabs,
    BTab,
    ProductList,
    ProductCategoryAdd,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: null,
        name: null,
        is_active: true,
        slug: null,
        parent_id: null,
        content: null,
      },
      activeOptions: Config.activeOptions,
      items: [],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    handleSort(data) {
      this.items = data
    },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_category/tree?template_id=${templateId}&limit=1000`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.items = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        name: this.model.name,
        is_active: Number(this.model.is_active === true ? 1 : 0),
        slug: this.model.slug,
        content: this.model.content,
      }
      if (this.model.parent_id !== null) {
        params.parent_id = this.model.parent_id
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_category`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Product Category success',
                  },
                })
                this.$router.push('/product_category/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
